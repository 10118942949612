<template>
  <main class="md:p-4">
    <sqr-page-header title="Polices" class="p-4"/>
    <sqr-progress v-show="loading" />
    <sqr-error-banner :error="loadError" />
    <policies-comp
      :bid="bid"
      :order-by-default="[['updated', 'desc']]"
      :force-where="where"
      :updated="true"
      style="padding-top: 0rem;"
    />
  </main>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import SqrProgress from '@/sqrd-ui/SqrProgress';
import SqrErrorBanner from '@/sqrd-ui/SqrErrorBanner';
import PoliciesComp from '@/components/Policies';
export default {
  name: 'Policies',
  components: { SqrProgress, SqrErrorBanner, PoliciesComp },
  props: {
    bid: String
  },
  computed: {
    ...mapGetters('brokers', ['broker']),
    ...mapState('policies', ['loading', 'loadError']),
    where() {
      if (this.$route.query.insurerId) {
        return ['insurerId', '==', this.$route.query.insurerId];
      } else if (this.$route.query.branchId) {
        return ['branchId', '==', this.$route.query.branchId];
      } else {
        return undefined;
      }
    }
  }
};
</script>
