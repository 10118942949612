<template>
  <div>
    <div class="py-2 flex justify-end" v-if="!forceWhere">
      <sqr-input-checkbox
        placeholder="policies_display_cancelled"
        v-model="archived"
        @change="reload()"
      />
    </div>
    <sqr-progress v-show="loading" />
    <div class="flex flex-col">
      <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200"
        >
          <table class="min-w-full" v-if="!loading && !loadError">
            <thead>
              <tr>
                <th
                  v-if="updated"
                  @click="sortBy({ value: ['updated'] })"
                  class="whitespace-no-wrap"
                >
                  {{ $t('policy_updated') }}
                </th>
                <th @click="sortBy({ value: ['insurerName'] })">
                  {{ $t('policy_insurer') }}
                </th>
                <th @click="sortBy({ value: ['branchAbrev'] })">
                  {{ $t('policy_branch') }}
                </th>
                <th @click="sortBy({ value: ['number'] })">
                  {{ $t('policy_number') }}
                </th>
                <th @click="sortBy({ value: ['dateStart'] })">
                  {{ $t('policy_date_start') }}
                </th>
                <th @click="sortBy({ value: ['dateEnd'] })">
                  {{ $t('policy_date_until') }}
                </th>
                <th
                  @click="sortBy({ value: ['premium'] })"
                  class="has-text-right"
                >
                  {{ $t('policy_premium') }}
                </th>
                <th class="">{{ $t('policy_note') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                :key="policy.id"
                class="row"
                v-for="policy in records"
                :class="{ strikeout: policy.archived }"
              >
                <td v-if="updated">{{ policy.updated | relative }}</td>
                <td>{{ policy.insurerName }}</td>
                <td>{{ policy.branchAbrev }}</td>
                <td>
                  <sqr-router-link
                    :label-raw="policy.number || 'N/A'"
                    :to="{
                      name: 'relation-policy',
                      params: { bid, rid: policy.relationId, pid: policy.id }
                    }"
                    is-link
                  />
                </td>
                <td>{{ policy.dateStart | date }}</td>
                <td>{{ policy.dateEnd | date }}</td>
                <td class="has-text-right">
                  {{ policy.premium | amount}}
                </td>
                <td>
                  <div class="truncate w-32" :title="policy.note">{{ policy.note }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="field" style="margin: 0 0.75rem;">
        <sqr-fire-page
          v-bind="pagination"
          @page-set="pageSet"
          @page-size="pageSize"
        />
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
th {
  @apply px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider;
}
tr:nth-child(odd) {
  @apply bg-white;
}
tr:nth-child(even) {
  @apply bg-gray-50;
}
td {
  @apply px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-900;
}
</style>

<script>
import { mapGetters, mapMutations, mapActions, mapState } from 'vuex';
import SqrInputCheckbox from '@/sqrd/SqrInputCheckbox';
import SqrFirePage from '@/sqrd/SqrFirePage';
import amount from '@/filters/amount';
import date from '@/filters/date';
import relative from '@/filters/relative';

export default {
  name: 'Policies',
  components: { SqrInputCheckbox, SqrFirePage },
  filters: { amount, date, relative },
  props: {
    bid: String,
    rid: String,
    orderByDefault: { type: Array, default: () => [['insurerName', 'asc']] },
    forceWhere: { type: Array },
    updated: { Boolean }
  },
  data() {
    return { archived: false };
  },
  computed: {
    ...mapState('policies', ['loading', 'records', 'loadError']),
    ...mapGetters('policies', ['pagination'])
  },
  mounted() {
    this.reload();
  },
  beforeDestroy() {
    this.unsub();
  },
  methods: {
    ...mapActions('policies', [
      'sub',
      'unsub',
      'pageSet',
      'pageSize',
      'sortBy',
      'where'
    ]),
    reload() {
      const where = [];
      let orderBy = [];
      if (this.forceWhere) {
        where.push(this.forceWhere);
      } else {
        if (this.rid) {
          where.push(['relationId', '==', this.rid]);
        }
        if (!this.archived) {
          where.push(['archived', '==', false]);
        }
        orderBy = this.orderByDefault;
      }
      this.sub({
        path: ['brokers', this.bid, 'policies'],
        orderBy,
        where
      });
    }
  }
};
</script>
