<template>
  <nav class="pagination hidden-print" role="navigation" aria-label="pagination">
    <div class="pagination-sizes">
      <div class="select">
        <select :value="pageSize" @change="$emit('page-size', {pageSize: parseInt($event.target.value)})">
          <option v-for="size in pageSizes" :key="size" :value="size">{{size}}</option>
        </select>
      </div>
    </div>

    <a
      class="pagination-previous"
      :aria-label="$t('pagination_goto', {page})"
      @click="$emit('page-set', {page: page - 1})"
      :disabled="page === 0"
    >
      {{$t('pagination_previous')}}
    </a>

    <a
      class="pagination-next"
      :aria-label="$t('pagination_goto', {page: page + 2})"
      @click="$emit('page-set', {page: page + 1})"
      :disabled="totalPages && (page + 1 >= totalPages)"
    >
      {{$t('pagination_next')}}
    </a>

    <ul class="pagination-list">
      <li v-if="page > 1">
        <a
          class="pagination-link"
          :aria-label="$t('pagination_goto', {page:1})"
          @click="$emit('page-set', {page: 0})"
        >1</a>
      </li>
      <li v-if="page > 2">
        <span class="pagination-ellipsis">&hellip;</span>
      </li>
      <li v-if="page > 0">
        <a
          class="pagination-link"
          :aria-label="$t('pagination_goto', {page})"
          @click="$emit('page-set', {page: page - 1})"
        >{{page}}</a>
      </li>
      <li>
        <a
          class="pagination-link is-current"
          :aria-label="$t('pagination_goto', {page: page + 1})"
          aria-current="page"
          @click="$emit('page-set', {page})"
        >{{page + 1}}</a>
      </li>
      <li v-if="!(totalPages && (page + 1 >= totalPages))">
        <a
          class="pagination-link"
          :aria-label="$t('pagination_goto', {page: page + 2})"
          @click="$emit('page-set', {page: page + 1})"
        >{{page + 2}}</a>
      </li>
      <li v-if="!totalPages || (page + 3 < totalPages)">
        <span class="pagination-ellipsis">&hellip;</span>
      </li>
      <li v-if="totalPages && (totalPages > 2) && (page + 2 < totalPages)">
        <a
          class="pagination-link"
          :aria-label="$t('pagination_goto', {page: totalPages})"
          @click="$emit('page-set', {page: totalPages - 1})"
        >{{totalPages}}</a>
      </li>
    </ul>
  </nav>
</template>


<script>
export default {
  name: 'SqrFirePage',
  props: {
    page: { type: Number, default: 0 },
    totalPages: {type: Number, default: undefined},
    pageSize: { type: Number, default: 10 },
    pageSizes: { type: Array, default: () => [10, 25, 50, 100] },
    orderByField: String,
    orderByDirection: { type: String, default: 'asc' }
  },
  methods: {
    log (event) {
      console.log(event);
    }
  }
};
</script>
